import createReducer from '@/lib/redux_updeep';
import { getLocalKey } from '../local_store';
import { QUAL_FLOW_TYPE } from '../store_keys';

const initialState = {
  layoutConfig: {},
  currentLayout: 'DEFAULT_LAYOUT',
  language: 'en',
  lastBigCookieHit: '',
  selectedTenureData: {},
  txnResponse: '',
  geolocation: {},
  qualFlowType: getLocalKey(QUAL_FLOW_TYPE) || 'NEW_CONTROL',
  sequential: {},
  showOldFlow: process.env.VUE_APP_SEQUENTIAL_ALLOWWED !== 'true',
  kycOffers: null,
};

export default createReducer('APPCONFIG', initialState, {});
