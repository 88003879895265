export default {
  setupEmandate: {
    setupInfo: [
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    ],
    setupBtn: 'Setup Repayment',
    success: {
      text1: 'Congratulations!',
      text2: 'We have received your EMI payment.',
      subText:
        'It is subject to reliazation and reflect in the app within 3-5 business days',
      text3: 'Transaction done on',
      text4: 'Your reference number is',
      button: 'Done',
    },
    failure: {
      text1: 'Sorry!',
      text2: 'Your payment was not successful.',
      subText: 'Please try again',
      text3: 'Transaction done on',
      text4: 'Your reference number is',
      button: 'Done',
    },
  },
  RBL: {
    productOfferings: [
      {
        productCode: 'credit_card',
        productName: 'MoneyTap Card',
        productTitle: 'Credit Card Benefits',
        productDescription:
          'You can use your enture credit line limit as a credit card.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description: `Pay interest only on the amount you transfer into your bank account. If you dont use the credit line, you never have to pay any interest.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title: 'Instant Limit Top-Up. No paperwork.',
            description: `Whenever you pay back an EMI, your limit gets an instant top for the same amount. Use the money again without paper work.`,
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
          {
            title: 'Instant Money Transfer',
            description: `Whenever you have a cash need, simply use the Freo app to transfer money into your bank account instantly.`,
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title: 'Credit Card',
            description: `MoneyTap provides you with a credit card which is accepted everywhere.`,
            iconUrl: require('@/assets/images/ic_master_card.png'),
          },
          {
            title: '100% Cash Transfer',
            description: `You can transfer 100% of your credit card limit into your bank account. Only MoneyTap offers this feature.`,
            iconUrl: require('@/assets/images/ic_cash.png'),
          },
          {
            title: 'Welcome Benefits',
            description: `2000 Reward Points on activation of card within 30 days.`,
            iconUrl: require('@/assets/images/ic_points.png'),
          },
          {
            title: 'Instant Discount on Grofers',
            description:
              '10% instant discount up to total of Rs. 300/- in a month using code “MONEYTAP” on Grofers app (on max of 2 purchases)',
            iconUrl: require('@/assets/images/ic_grofers.png'),
          },
          {
            title: 'Cashback on Zomato',
            description:
              '20% cashback up to Rs. 200/- in a month (on any number of purchases)',
            iconUrl: require('@/assets/images/ic_zomato.png'),
          },
          {
            title: 'Free Movie Tickets',
            description:
              'Get 1+1 free movie ticket every month up to Rs. 200/- at BookMyShow for Saturday & Sunday shows.',
            iconUrl: require('@/assets/images/ic_bms.png'),
          },
          {
            title: 'Reward Points On All Purchases',
            description:
              'Earn 1 reward point for every Rs. 100 spent offline and earn 2 reward points for online purchases.',
            iconUrl: require('@/assets/images/ic_points.png'),
          },
          {
            title: 'Milestone Benefit',
            description:
              'Rs. 1000 Amazon Voucher on reaching spends of Rs. 2.5 lac in the year sent by Vouchagram through SMS/Email',
            iconUrl: require('@/assets/images/ic_amazon.png'),
          },
        ],
      },
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest',
            description: `Pay interest only on the amount you want to borrow.
                         <b>No interest charged</b> if you don’t borrow any money from your credit line.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<span style="color: var(--primary)">Instant</span> Money Transfer',
            description: `Whenever you have a cash need,
                         simply use the MoneyTap app to transfer money into your bank account instantly.`,
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Instant Limit Top-Up. <span style="color: var(--primary)">No paperwork</span>',
            description: `Whenever you pay back an EMI,
                         your limit gets an instant top-up for the same amount. Use the money again.`,
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      PostGraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [],
  },
  DMI: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description: `Pay interest only on the amount you want to borrow.
                            <b>No interest charged</b> if you don't borrow any money from your credit line.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<span style="color: var(--primary)">Instant</span> Money Transfer',
            description: `Whenever you have a cash need, simply
                            use the MoneyTap app to transfer money into your bank account instantly.`,
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <span style="color: var(--primary)">No paperwork</span>',
            description: `Whenever you close a loan,
                            your limit gets an instant top-up for the same amount. Use the money again.`,
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce Charges',
        value: 'Rs. 450 Per Dishonor',
        width: 'half',
      },
      {
        name: 'Overdue Charge',
        value: '2% PM on an amount of EMI Outstanding as on that date',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value:
          'Basis the withdrawal amount: 199 for 3k, 399 for 5k, 499 for 10k,\
            2% or 500 whichever is higher for >10k',
        width: 'full',
      },
    ],
  },
  MUSKMELON: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description: `Pay interest only on the amount you want to borrow. <b>No interest charged</b>
      if you don't borrow any money from your credit line.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money\
        into your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'MUSKMELON',
            name: 'MUSKMELON',
            channel: 'IMPS',
          },
          {
            // TODO: change icons and images
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher ' +
                  'for one or multiple transactions on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted\
            to select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be\
            credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce Charges',
        value: 'Rs. 650 Per Dishonor',
        width: 'half',
      },
      {
        name: 'Overdue Charge',
        value: '2% PM on an amount of EMI Outstanding as on that date',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value:
          'Basis the withdrawal amount: Rs. 499 for Rs. 5000 and Rs. 799 for Rs. 10000',
        width: 'full',
      },
    ],
  },
  INCRED: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a\
    loan/credit card currently.',
        channelPartners: [
          {
            code: 'INCRED',
            name: 'INCRED',
            channel: 'IMPS',
          },
          {
            // TODO: change images and icons
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher for one or multiple transactions\
            on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have an \
          existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number \
          when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted to \
          select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be \
          credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce Charges',
        value: 'Rs. 450 Per Dishonor',
        width: 'half',
      },
      {
        name: 'Overdue Charge',
        value:
          '2% per month will be levied for number of days EMI payment is delayed.',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value:
          'Basis the withdrawal amount: 299 for 3K, 449 for 5K, 699 for 10K,\
          625 or 750 for 15K to 25K, 2.5% to 3% for greater than or equal to 25K',
        width: 'full',
      },
    ],
  },
  FULLERTON: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce/ Late Payment Charges',
        value: 'Not exceeding Rs. 500 + Applicable GST',
        width: 'half',
      },
      {
        name: 'Penal Interest Charges',
        value: 'Not exceeding 2.5% per month accrued on daily basis',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value: 'Not exceeding upto 5% + Applicable GST',
        width: 'full',
      },
      {
        name: 'Foreclosure criteria',
        value:
          'Loan cannot be foreclosed within 3 months from the Disbursement date',
        width: 'full',
      },
      {
        name: 'Foreclosure/Prepayment Charges',
        value: 'Not exceeding 4% of the loan outstanding + Applicable GST',
        width: 'full',
      },
    ],
  },
  TAPSTART: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the Freo app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'TAPSTART',
            name: 'TAPSTART',
            channel: 'IMPS',
          },
          {
            // TODO: change icons and images
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher ' +
                  'for one or multiple transactions on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted\
            to select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be\
            credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce/ Late Payment Charges',
        value: 'Not exceeding Rs. 500 + Applicable GST',
        width: 'half',
      },
      {
        name: 'Penal Interest Charges',
        value: 'Not exceeding 2.5% per month accrued on daily basis',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value: 'Not exceeding upto 5% + Applicable GST',
        width: 'full',
      },
      {
        name: 'Foreclosure criteria',
        value:
          'Loan cannot be foreclosed within 3 months from the Disbursement date',
        width: 'full',
      },
      {
        name: 'Foreclosure/Prepayment Charges',
        value: 'Not exceeding 4% of the loan outstanding + Applicable GST',
        width: 'full',
      },
    ],
  },
  CREDITSAISON: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a\
    loan/credit card currently.',
        channelPartners: [
          {
            code: 'CREDITSAISON',
            name: 'CREDITSAISON',
            channel: 'IMPS',
          },
          {
            // TODO: change images and icons
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher for one or multiple transactions\
            on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have an \
          existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number \
          when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted to \
          select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be \
          credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce Charges',
        value: 'Rs. 450 Per Dishonor',
        width: 'half',
      },
      {
        name: 'Overdue Charge',
        value:
          '2% per month will be levied for number of days EMI payment is delayed.',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value:
          'Basis the withdrawal amount: 299 for 3K, 449 for 5K, 699 for 10K,\
          625 or 750 for 15K to 25K, 2.5% to 3% for greater than or equal to 25K',
        width: 'full',
      },
    ],
  },
  HDBFS: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a\
    loan/credit card currently.',
        channelPartners: [
          {
            code: 'HDBFS',
            name: 'HDBFS',
            channel: 'IMPS',
          },
          {
            // TODO: change images and icons
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher for one or multiple transactions\
            on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have an \
          existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number \
          when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted to \
          select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be \
          credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce Charges',
        value: 'Rs. 450 Per Dishonor',
        width: 'half',
      },
      {
        name: 'Overdue Charge',
        value:
          '2% per month will be levied for number of days EMI payment is delayed.',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value:
          'Basis the withdrawal amount: 299 for 3K, 449 for 5K, 699 for 10K,\
          625 or 750 for 15K to 25K, 2.5% to 3% for greater than or equal to 25K',
        width: 'full',
      },
    ],
  },
  LENDBOX: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date / Repayment Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce Charges',
        value: 'Rs. 450 Per Dishonor + applicable taxes',
        width: 'half',
      },
      {
        name: 'Overdue Interest Charges/ Penal charges',
        value: 'Upto Rs 500 + applicable taxes',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value: '10% of the withdrawal amount',
        width: 'full',
      },
      {
        name: 'Pre-payment charges / Foreclosure charges',
        value: 'Up to 5 % on outstanding principal + applicable taxes',
        width: 'full',
      },
    ],
  },
  CHOLAMANDALAM: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date / Repayment Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce Charges',
        value: 'Rs. 450 Per Dishonor',
        width: 'half',
      },
      {
        name: 'Overdue Interest Rate',
        value: 'Same as applicable interest rate',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value: '10% of the withdrawal amount',
        width: 'full',
      },
      {
        name: 'Pre-payment charges / Foreclosure charges',
        value: 'Up to 5 % on outstanding principal + applicable taxes',
        width: 'full',
      },
    ],
  },

  ASPIRE: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'ASPIRE',
            name: 'ASPIRE',
            channel: 'IMPS',
          },
          {
            // TODO: change icons and images
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher ' +
                  'for one or multiple transactions on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted\
            to select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be\
            credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce/ Late Payment Charges',
        value: 'Not exceeding Rs. 500 + Applicable GST',
        width: 'half',
      },
      {
        name: 'Penal Interest Charges',
        value: 'Not exceeding 2.5% per month accrued on daily basis',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value: 'Not exceeding upto 5% + Applicable GST',
        width: 'full',
      },
      {
        name: 'Foreclosure criteria',
        value:
          'Loan cannot be foreclosed within 3 months from the Disbursement date',
        width: 'full',
      },
      {
        name: 'Foreclosure/Prepayment Charges',
        value: 'Not exceeding 4% of the loan outstanding + Applicable GST',
        width: 'full',
      },
    ],
  },
  KOMAC: {
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'TAPSTART',
            name: 'TAPSTART',
            channel: 'IMPS',
          },
          {
            // TODO: change icons and images
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher ' +
                  'for one or multiple transactions on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted\
            to select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be\
            credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    emiInformation: [
      {
        name: 'EMI Date',
        value: '5th of every month',
        width: 'half',
      },
      {
        name: 'Bounce/ Late Payment Charges',
        value: 'Not exceeding Rs. 500 + Applicable GST',
        width: 'half',
      },
      {
        name: 'Penal Interest Charges',
        value: 'Not exceeding 2.5% per month accrued on daily basis',
        width: 'full',
      },
      {
        name: 'Processing Fees',
        value: 'Not exceeding upto 5% + Applicable GST',
        width: 'full',
      },
      {
        name: 'Foreclosure criteria',
        value:
          'Loan cannot be foreclosed within 3 months from the Disbursement date',
        width: 'full',
      },
      {
        name: 'Foreclosure/Prepayment Charges',
        value: 'Not exceeding 4% of the loan outstanding + Applicable GST',
        width: 'full',
      },
    ],
  },
};
