import { MIN_SALARY, MAX_SALARY, AGE_LIMIT } from '@/lib/app_consts';

export default {
  step1: 'Step 1',
  step2: 'Step 2',
  step3: 'Step 3',
  continue: 'Continue',
  submit: 'Submit',
  benefits: {
    title: 'Freo Benefits',
    info: [
      {
        title: 'Borrow nothing, pay nothing',
        description:
          'Reserve the full amount, pay interest only on what you transfer to your bank account.',
        backGround: 'benefits1',
      },
      {
        title: 'Credit that never ends',
        description:
          'Get a limit top-up with every repayment. Borrow the money again, with no reapplication.',
        backGround: 'benefits2',
      },
      {
        title: 'Get money instantly',
        description:
          'Anytime you need money, transfer it to your bank account in minutes from the app.',
        backGround: 'benefits3',
      },

      {
        title: 'Fee & Tenure',
        description: `Interest Rate  - 13% to 36% PA<br/>
        Processing Fee - 0% to 7%<br/>
        Loan Tenure    - 2 to 36 Months<br/>
        Loan Amount    - Rs. 3,000 to Rs. 5,00,000`,
        backGround: 'benefits7',
      },
      /* {
        title: 'No limits with 100% cash transfer',
        description: 'Transfer 100% of your credit limit into your bank account with only MoneyTap.',
        backGround: 'benefits5'
      },
      {
        title: 'Get rewarded with every spend',
        description: '4000 rewards points on spends crossing Rs. 1.2 Lakh a year, redeemable for Rs. 1000 vouchers.',
        backGround: 'benefits6'
      } */
    ],
  },
  benefits_CF: {
    title: 'Freo Benefits',
    info: [
      // {
      //   title: 'No usage, no interest!',
      //   description: 'Pay only the sale price, nothing extra.',
      //   backGround: 'benefits1'
      // },
      {
        title: '0% interest, 0 processing fee',
        description: `Shop for the latest smartphones, electronics, and lifestyle products with affordable
                      No Cost EMI* plans.`,
        backGround: 'benefits2',
      },
      {
        title: 'Flexible repayment options',
        description: `Use the money as you like it Repay
        flexibly in 3*, 6, 9, 12 months`,
        backGround: 'benefits4',
      },
      {
        title: 'No debit card or credit card required',
        description: `Meet all your shopping needs under on
        roof with MoneyTap No Cost EMI* -
        without a credit card or debit card.`,
        backGround: 'benefits3',
      },
    ],
  },
  benefits_FC: {
    title: 'Freo Benefits',
    info: [
      {
        title: 'Borrow nothing, pay nothing',
        description:
          'Reserve the full amount, pay interest only on what you transfer to your bank account.',
        backGround: 'benefits1',
      },
      {
        title: 'Get money instantly',
        description:
          'Anytime you need money, transfer it to your bank account in minutes from the app.',
        backGround: 'benefits3',
      },
      {
        title: 'Fee & Tenure',
        description: `Interest Rate - 13% - 36% PA<br/>
        Processing Fee - 2%<br/>
        Loan Tenure - 2 - 36 Months<br/>
        Loan Amount - 3,000 to 5 Lakh`,
        backGround: 'benefits7',
      },
    ],
  },
  rejectedReasons: {
    title: 'Application Not Approved',
    subtitle: 'Few possible reasons for non-approval',
    reasons: [
      {
        question: 'Having too many payment obligations',
        answer: `If you already have too many outstanding credit balances and payments,
        we avoid adding more  burden to make sure you don't fall into a debt trap`,
      },
      {
        question: 'Not meeting our eligibility criteria',
        answer: `If any of your given profile details don't match our eligibility
        criteria with any of the partner banks`,
      },
      {
        question: 'Irregular or delayed salary payments',
        answer: `Having a stable income is a favourable factor for our banking
        partners to lend money.`,
      },
      {
        question: 'Taken a personal loan recently',
        answer: `Our partner banks avoid offering additional credit
        if you've recently taken a personal loan.`,
      },
      {
        question: 'Applied for loans with other banks',
        answer: `If you've made too many enquiries with other banks
        for a loan recently, it's not a favourable factor`,
      },
      {
        question: 'Delayed or defaulted on past repayments',
        answer: `A bad repayment history usually leads to a negative
        credit report which is avoided by our banking partners.`,
      },
    ],
  },
  quickProfile: {
    pageTitle: 'Get started by creating your profile',
  },
  qualification: {
    pageTitle: 'Check your Credit Limit',
    subTitle: 'Answer these questions to know your limit',
    inputs: {
      fullName: {
        title: 'Full Name',
        placeholder: 'Full Name as per PAN',
      },
      dob: {
        title: 'Date of Birth as per PAN',
        subTitle: 'Accepted Format: DD/MM/YYYY e.g. 29/02/1992',
        placeholder: 'dd/mm/yyyy',
      },
      gender: {
        title: 'Gender',
      },
      pan: {
        title: 'PAN',
        subTitle: 'We need this to calculate your credit limit',
      },
      pincode: {
        title: 'Pincode',
        placeholder: 'Current Residence Pincode',
      },
      isSalaried: {
        title: 'Are you a full time Salaried employee?',
        subTitle:
          ' Select <b>"Others"</b> if you are part-time/contract/self-employed professional',
      },
      salary: {
        title: 'Monthy Take home Salary',
      },
      greaterHouseHoldIncome: {
        title:
          'I confirm that my household income is above Rs. 25,000 per month.',
        subtitle:
          'Household income is the total income of your husband or wife, and unmarried children.',
      },
      submit: {
        title: 'Check Credit limit',
      },
    },
  },
  residenceAddress: {
    pageTitle: 'Where do you live?',
    subTitle: 'Answer these questions to know your limit',
    inputs: {
      addressLine1: {
        title: 'Address Line 1',
        subTitle: 'Flat/house, block number, building society name',
      },
      addressLine2: {
        title: 'Address Line 2',
        subTitle: 'Street details, area, locality, landmark',
      },
      pincode: {
        title: 'Pincode',
      },
      city: {
        title: 'City',
      },
      state: {
        title: 'State',
      },
      submit: {
        title: 'Check Credit limit',
      },
    },
  },
  residenceDetails: {
    pageTitle: 'Current Residence Details',
  },
  financialDetails: {
    pageTitle: 'Work and Income Details',
  },
  submitApplication: {
    pageTitle: 'Verification and application submission',
    userConsentAndTnc: `I hereby agree that all information provided by me
     is correct and I agree to the terms of
     <a href="https://www.moneytap.com/user-consent.html" target="_blank">
     <u>User Consent Form</u></a>. I also agree with MoneyTap's
     <a href="https://www.moneytap.com/t-and-c.html" target="_blank">
     <u>Terms and Conditions</u></a> and
     <a href="https://www.moneytap.com/privacy-policy.html" target="_blank">
     <u>Privacy Policy</u></a>`,
    userConsentAndTncObj: {
      line1: `By pressing 'Get OTP', you agree to`,
      line2: `MoneyTap's`,
      link2Href: 'https://www.moneytap.com/terms-and-policies.html',
      link2Text: 'Terms & Policies',
      // line1:
      //   'I hereby agree that all information provided by me is correct and I agree to the terms of ',
      // link1Href: 'https://www.moneytap.com/user-consent.html',
      // link1Text: 'User Consent Form',
      // line1Conjunction: 'and ',
      // dataShareText: 'Data Sharing Policy',
      // dataShareLink:
      //   'https://www.moneytap.com/partner-data-sharing-consent-document.html?utm_source=app',
      // line1End: '. ',
      // line2: "I also agree with MoneyTap's ",
      // link2Href: 'https://www.moneytap.com/t-and-c.html',
      // link2Text: 'Terms and Conditions ',
      // line2Conjunction: 'and ',
      // link3Href: 'https://www.moneytap.com/privacy-policy.html',
      // link3Text: 'Privacy Policy'
    },
    userConsent: `I hereby declare that all the information provided
    by me is correct and I accept the terms of
    <a href="https://www.moneytap.com/user-consent.html" target="_blank">
    <u>User Consent Form</u></a>`,
    userConsentObj: {
      line1:
        'I hereby agree that all the information provided by me is correct and I accept the terms of ',
      link1Href: 'https://www.moneytap.com/user-consent.html',
      link1Text: 'User Consent Form',
    },
    tandc: `I hereby agree to the
    <a href="https://www.moneytap.com/t-and-c.html" target="_blank">
    <u>Terms and Conditions</u></a> and
    <a href="https://www.moneytap.com/privacy-policy.html" target="_blank">
    <u>Privacy Policy</u></a>`,
    tandcObj: {
      line1: 'I hereby agree to the ',
      link1Href: 'https://www.moneytap.com/t-and-c.html',
      link1Text: 'Terms and Conditions',
      line1Conjunction: ' and ',
      link2Href: 'https://www.moneytap.com/privacy-policy.html',
      link2Text: 'Privacy Policy',
    },
    whatsAppOptIn: `Opt for WhatsApp updates`,
    info: [
      {
        title: '100% data security',
        description:
          'No information is shared with third parties without prior consent',
      },
      /* {
        title: 'Zero Spam',
        description: 'We will never spam with you calls or SMS'
      }, */
      {
        title: 'Communication & Support',
        description: 'Receive important updates about your application',
      },
    ],
    panError: {
      heading: 'PAN Card Number',
      subHeading: 'Please enter a valid PAN number and try again.',
      title: 'PAN Card Number',
      update: 'Update & Submit',
    },
    waitingForResponse: `Waiting for credit response`,
    waitingForResponse2: `You've done it! Hold on as we check your
    profile details for an approval decision.`,
    pleaseWait: 'Decision expected within',
    longerThanExpected:
      'Sorry, our systems seem to be having a bad day. This is taking longer than expected. Would you like us to notify you when done?',
    notifyVia: 'Yes, notify me via SMS',
    submitBtn: 'Check Limit',
    notifiedText:
      'You will be notified by SMS once we have your application status.',
  },
  rejectedApplication: {
    title: `We're sorry, we couldn't approve your application right now`,
    description: `Looks like none of our banking partners were a match for your profile.`,
    reapply: {
      title: 'Congratulations, you can now re-apply',
      description: `We were not able to approve your application earlier. You are eligible to reapply now.`,
      action: 'Re-apply',
    },
    reroute: {
      title:
        'Sorry, one of our banking partners cannot take your application forward. However, we are looking for another one for you. Would you like us to continue searching and re-apply your application?',
      description:
        'Note: You may be required to go through the KYC process once more.',
      action: 'Continue with other partner',
    },
    reapplyCard: {
      title: 'Try again in 3 months?',
      description: `You can always re-apply once 3 months are complete. Just login with your
      registered contact details to update your profile details and try again.`,
      action: 'Notify me after 3 months',
    },
    ratingCard: {
      title: 'How was your experience?',
      description: `Tap the number of stars to rate us and help improve our services.`,
      action: 'Rate now',
    },
  },
  noResponse: {
    title: `Looks like something went wrong`,
    subtitle:
      'We’re unable to process your application right now. Please try again in a few minutes',
  },
  approvedApplication: {
    title: 'Congratulations!<br>You have been approved up to',
    CF: {
      title: 'Congratulations!<br>You have been approved for',
      subtitle: require('@/assets/images/CF/no_cost_EMI.png'),
      onlinePartners: {
        title: 'Shop with your favourite merchants',
        list: [
          {
            id: 'AMAZON',
            imageUrl: require('@/assets/images/CF/amazon.png'),
            title: 'Amazon',
          },
          {
            id: 'FLIPKART',
            imageUrl: require('@/assets/images/CF/flipkart.png'),
            title: 'Flipkart',
          },
          {
            id: 'MAKE_MY_TRIP',
            imageUrl: require('@/assets/images/CF/mmt.png'),
            title: 'MakeMyTrip',
          },
          {
            id: 'MYNTRA',
            imageUrl: require('@/assets/images/CF/myntra.png'),
            title: 'Myntra',
          },
        ],
      },
      offlinePartners: {
        title: 'Our offline partners',
        list: [
          {
            id: 'PINE_LABS',
            imageUrl: require('@/assets/images/CF/pine_labs.png'),
            title: 'Pine Labs',
          },
        ],
      },
      thumbnailText: `Still confused?<br>Watch this video`,
      thumbnailTextMobile: `Still confused? Watch this video`,
      whatsNext: {
        title: `What's next?`,
        step1: 'Download the app',
        step2: 'Complete KYC',
        step3: 'Start shopping',
      },
      infoCard: {
        title: 'Important:',
        points: ['Final approval is subject to further KYC checks.'],
      },
      downloadAndriod: require('@/assets/images/ic_playstore.png'),
      downloadIos: require('@/assets/images/ic_app_store.png'),
    },
    whatsNext: {
      title: `What's next?`,
      step1: 'Download the app',
      step2: 'Complete KYC',
      step3: 'Transfer Money',
    },
    downloadAndriod: require('@/assets/images/ic_playstore.png'),
    downloadIos: require('@/assets/images/ic_app_store.png'),
  },
  perfios: {
    pageTitle: 'Securely submit bank statements to know your credit limit',
    viaNetbanking: {
      title: 'Verify with netbanking',
      subtitle: 'Takes 30 secs',
      info: [
        {
          title: 'Choose your salary bank account in the next step',
          description: 'Only salary account should be selected',
        },
        {
          title: 'Log in using netbanking',
          description:
            "Log in with your bank's netbanking username and password",
        },
        {
          title: "Submit statements, and you're done!",
          description:
            'We ONLY get access to read your bank statements. 100% safe and secure.',
        },
      ],
      continue: 'Continue with Netbanking',
      checkStatus: 'Check Status',
    },
    viaUpload: {
      title: 'Verify by uploading PDF',
      subtitle: 'Takes 2 mins',
      info: [
        {
          title: "Download bank statements from your bank's website or app",
          description: 'Download last 3 month statements',
        },
        {
          title: "Upload statements on our secure portal, and you're done!",
          description: '100% safe and secure',
        },
        // {
        //   title: 'Verification of application details',
        //   description:
        //     'We will auto-detect your salary amount only. 100% safe and secure process.'
        // }
      ],
      continue: 'Upload statements',
    },
    contactText: `In case you face any issues or have any doubts, contact our Support team on
    <a class="orangeLink" href="mailto:hello@moneytap.com">hello@moneytap.com</a>`,
    multipleAccounts:
      'Multiple accounts detected. Please choose the correct account and submit',
    accountDetails: {
      title: 'Account Details',
      bankName: 'Bank Name',
      accountNumber: 'Account Number',
      ifsc: 'IFSC Code',
    },
    popupWindowName: 'MoneyTap - Submit bank statements via net banking',
  },
  creditEligibility: {
    pageTitle: 'CRIF Verification',
    description:
      'You will have to answer the questions below to complete the verification',
  },
  inputs: {
    name: {
      title: 'Full name as per PAN card',
      placeHolder: 'Full Name',
    },
    dob: {
      title: 'Date of birth',
      subtitle: 'Enter as per PAN card for a smoother approval process',
    },
    gender: {
      title: 'Gender',
      placeHolder: 'Select gender',
    },
    couponCode: {
      title: 'Coupon Code (optional)',
      placeHolder: 'Enter Your Coupon Code',
    },
    city: {
      title: 'City of residence',
      subtitle: 'Select city where you are residing currently',
    },
    creditHistory: {
      title: 'Credit history',
      subtitle: 'Your KYC will be conducted for this city',
    },
    phone: {
      title: 'Your Phone Number',
      subtitle: 'This number will be verified by OTP',
    },
    residenceType: {
      title: 'What’s your residence type?',
      subtitle: 'What’s your residence type?',
    },
    yearsInResidence: {
      title: 'Years in current residence',
    },
    yearsInCity: {
      title: 'Years in current city',
    },
    residenceAddress: {
      title: 'Current residence address',
      placeHolder1: 'House number, Apartment name',
      placeHolder2: 'Street, Area',
      pincode: 'Pincode',
      city: 'City',
      state: 'State',
    },
    companyName: {
      title: 'Where do you work currently?',
      subtitle: 'If you are self-employed, type “Others”',
      placeHolder: 'Start typing ...',
    },
    companyType: {
      title: 'What is your company type?',
    },
    designation: {
      title: 'What is your designation?',
      placeHolder: 'Start typing ...',
    },
    pan: {
      title: 'What’s your PAN card number?',
      subtitle: 'Required to check your eligibility.',
    },
    employmentType: {
      title: 'What is your job type?',
    },
    bankIfscPrefix: {
      title: 'Which bank account do you use? (select one)',
    },
    experience: {
      title: 'Total work experience?',
    },
    experienceInCurrent: {
      title: 'Years in current company?',
    },
    officeEmail: {
      title: 'Your office email',
      subtitle: 'Eg: rahul@examplecompanyname.com',
    },
    salary: {
      title: 'What’s your monthly take-home income/salary?',
      subtitle: 'Also, please choose how you receive it',
      via: 'via',
    },
    greaterHouseHoldIncome: {
      title: 'Is your household income more than ₹25,000/month?',
      subtitle:
        'Household income is the total income of your husband or wife, and unmarried children.',
    },
    mobile: {
      title: 'Your mobile number',
    },
    otp: {
      title: 'Enter OTP',
    },
    inviteCode: {
      title: 'Have an promo code?',
    },
  },
  errors: {
    firstName: {
      required: 'First name is a required field',
      name: 'Name should begin with a letter. Please check to continue',
      alpha_spaces:
        'Name can contain only letters and spaces. Please check to continue',
      validName: `Salutations such are 'Mr', 'Sri', 'Dr' are not allowed. Please remove it to continue`,
    },
    fullName: {
      required: 'Full Name is required field',
      name: 'Name should begin with a letter. Please check to continue',
      alpha_spaces:
        'Name can contain only letters and spaces. Please check to continue',
      validName: `Salutations such are 'Mr', 'Sri', 'Dr' are not allowed. Please remove it to continue`,
    },
    middleName: {
      name: 'Name should begin with a letter. Please check to continue',
      alpha_spaces:
        'Name can contain only letters and spaces. Please check to continue',
    },
    lastName: {
      required: 'Last name is a required field',
      name: 'Name should begin with a letter. Please check to continue',
      alpha_spaces:
        'Name can contain only letters and spaces. Please check to continue',
    },
    dob: {
      required: 'Date of birth is required',
      validAge: `To apply, age must be between ${AGE_LIMIT.MIN} to ${AGE_LIMIT.MAX} years`,
      validDate: 'Invalid Date',
    },
    gender: {
      required: 'Gender is a required field',
    },
    inviteCode: {
      couponCode: 'Please enter valid coupon code',
    },
    currentCity: {
      required: 'Current city is a required field',
      validCity: 'Sorry, we’re currently available only in the given cities',
    },
    creditHistory: {
      required: 'Credit history is a required field',
    },
    phone: {
      required: 'Phone number is a required field',
      integer: 'Phone number must have 10 digits only',
    },
    residenceType: {
      required: 'Type of residence is a required field',
    },
    yearsInResidence: {
      required: 'Current residence duration is a required field',
      validTenure:
        'Years in current residence cannot be more than the current city. Check selection',
    },
    yearsInCity: {
      required: 'Current city duration is a required field',
      validTenure:
        'Years in current city cannot be less than current residence years. Check selection',
    },
    addressLine1: {
      required: 'Address is a required field',
      address: 'Address field can have only these special characters [./,-]',
      noEscChars: 'Address field cannot have the special character ‘’',
      repeatChars: 'Address field does not allow three repeated characters.',
      min: 'Address line 1 cannot have less than 3 characters',
      max: 'Address line 1 cannot have more than 40 characters',
    },
    addressLine2: {
      address: 'Address field can have only these special characters [./,-]',
      noEscChars: 'Address field cannot have the special character ‘’',
      repeatChars: 'Address Line 1 does not allow three repeated characters.',
      min: 'Address line 2 cannot have less than 3 characters',
      max: 'Address line 2 cannot have more than 80 characters',
    },
    pincode: {
      required: 'Pincode is a required field',
      integer: 'Pincode must only have numbers. Please check to continue',
      limit: 'Pincode must be 6 digits only. Please check to continue',
      validPincode:
        'Your chosen city and given pincode don’t match. Please check to continue',
    },
    city: {
      required: 'City is a required field',
    },
    state: {
      required: 'State is a required field',
    },
    companyName: {
      required: 'Company name is a required field',
      companyName: 'Company name cannot have special characters',
    },
    companyType: {
      required: 'Company type is a required field',
    },
    designation: {
      required: 'Designation is a required field',
      noSpecialChars:
        'Designation cannot have numbers or special characters. Please check to continue',
    },
    pan: {
      required: 'PAN number is a required field',
      pancard:
        'Given PAN number is not in the required format and is invalid. Please check to continue',
    },
    employmentType: {
      required: 'Employment type is a required field',
      validJobType: 'We currently support only the above job types',
    },
    bankIfscPrefix: {
      required: 'Bank name is a required field',
    },
    experience: {
      required: 'Total work experience is a required field',
      validTenure:
        'Total work experience cannot be less than the current work experience. Please check selection',
    },
    experienceInCurrent: {
      required: 'Current work experience is a required field',
      validTenure:
        'Current work experience cannot be more than the total work experience. Please check selection',
    },
    officeEmail: {
      required: 'Work email is a required field',
      email:
        'Given email is not in the correct format. Please check to continue',
    },
    salaryMode: {
      required: 'Mode of salary is a required field',
    },
    salary: {
      required: 'Salary is a required field',
      integer:
        'Salary can have numbers only, no alphabets or special characters allowed',
      minValue: `To apply, salary must be more than Rs. ${MIN_SALARY}.`,
      maxValue: `To apply, salary must be less than Rs. ${MAX_SALARY}.`,
    },
    isSalaried: {
      required: 'Please select if you are salaried or not',
    },
    houseHoldIncome: {
      required: 'Household income confirmation is required',
    },
    mobile: {
      required: 'Phone number is a required field',
      integer: 'Phone must have 10 numbers only, with no special characters',
    },
    otp: {
      required: 'Please enter the OTP sent to your mobile number',
      integer: 'OTP entered is incorrect. Please check to continue',
    },
    tandc: {
      required:
        'Please accept the Terms and Conditions to complete registration',
    },
    userConsent: {
      required:
        'Please accept the given ‘User consent’ form to complete registration',
    },
    userConsentAndTnc: {
      required: `Please accept the T&C and the given 'User Consent' form to complete registration`,
    },
  },
  notifications: {
    /* Submit Application */
    bigCookieFetchError: {
      type: 'error',
      title: 'Hmm, something went wrong',
      message:
        'We couldn’t submit your application Please refresh the page  and try again',
    },
    signupFailedError: {
      type: 'error',
      title: 'Couldn’t register profile',
      message: 'Please check your internet or try again in sometime',
    },
    otpSuccess: {
      type: 'success',
      showClose: true,
      title: 'OTP sent',
      message: 'Please enter the OTP sent to your given number',
    },
    otpFail: {
      title: 'Could not send OTP',
      type: 'error',
      showClose: true,
      message: 'Please check your given number and try again',
    },
    buildProfileError: {
      title: 'Hmm, something went wrong',
      type: 'error',
      message:
        'We couldn’t register your profile. Please try again in sometime',
    },
    /* Step Progress */
    fillForm: {
      type: 'warning',
      title: 'Complete current step',
      message:
        'Skipping steps is not allowed. To continue to the next steps, please complete the current one',
      showClose: true,
    },
    /* Perfios Validation */
    enablePopup: {
      type: 'warning',
      title: 'Allow pop-up access',
      message:
        'Popups are blocked. To submit bank statements, please allow access',
    },
    submitStatementFail: {
      type: 'error',
      title: 'Couldn’t submit bank statements',
      message: 'We’re not sure what went either. Please try again in sometime.',
    },
    noBankDetailsNetbanking1: {
      type: 'error',
      title: 'Submit Bank Statements',
      message:
        'No Bank Details found in your submitted statements. Please try submitting your bank statement again',
    },
    noBankDetailsNetbanking2: {
      type: 'warning',
      title: 'Check selected account',
      message: `Couldn’t find a salary account attached to your given bank details.
      Please check details or try again with another account`,
    },
    unableToProcess: {
      type: 'error',
      title: 'Couldn’t process bank details',
      message:
        'Sorry, something went wrong. Please submit our details in sometime again',
    },
    initiateUploadError: {
      type: 'error',
      title: 'Couldn’t upload bank statements',
      message:
        'Please check your internet connection and try again in sometime',
    },
  },
  loadingMessages: {
    loading: 'Loading ...',
    /* Submit Application */
    gathering: 'Gathering your application details...',
    authenticating: 'Authenticating...',
    buildProfile: 'Building profile...',
    submit: 'Submitting...',
    /* Perfios Validation */
    scrutinize: 'Scrutinizing your bank details...',
  },
};
