import Vue from 'vue';
import Router from 'vue-router';
import { isCustomerAuthenticated } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { updateLayoutConfig } from '@/lib/layout_config';
import { layoutConfig } from '@/lib/layout_consts';
import { get } from '@/lib/helpers';
import { layoutConfig as currentLayoutConfig } from '@/store/getters/app_config';
import { retrieveBigCookie } from '@/store/actions/customer';
import { isMicroappsEnabled } from '@/lib/utils';
import { storeAccessDetails } from '@/store/actions/auth';
import { appRoutes } from './appRoutes';
import { cmRoutes } from './cmRoutes';
import { gpayRoutes } from './gpayRoutes';
import { qualRoutes } from './qualRoutes';
import { gpayQualRoutes } from './gpayQualRoutes';
import { magicLinkRoutes } from './magicLinkRoutes';
import { loanRestructureRoutes } from './loanRestructureRoutes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    appRoutes,
    cmRoutes,
    gpayRoutes,
    {
      path: '/',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.login', ''),
      component: () =>
        import(/* webpackChunkName: "login" */ '@/views/CustomerLogin.vue'),
      beforeEnter: async (to, from, next) => {
        if (isMicroappsEnabled()) {
          updateLayoutConfig('GPAY_LAYOUT');
          next({ path: '/gpay' });
        } else {
          updateLayoutConfig('DEFAULT_LAYOUT');
          next();
        }
      },
    },
    {
      path: '/login',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.loginComponent', ''),
      component: () =>
        import(
          /* webpackChunkName: "loginComponent" */ '@/components/LoginComponent.vue'
        ),
      beforeEnter: async (to, from, next) => {
        if (isMicroappsEnabled()) {
          updateLayoutConfig('GPAY_LAYOUT');
          next({ path: '/gpay' });
        } else {
          updateLayoutConfig('DEFAULT_LAYOUT');
          next();
        }
      },
    },
    {
      path: '/gpay',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.login', ''),
      component: () =>
        import(
          /* webpackChunkName: "gpay-wrapper" */ '@/views/GpayWrapper.vue'
        ),
    },
    {
      path: '/post-aip-reject',
      name: 'bankerPortalRejected',
      meta: { requiresAuth: true },
      redirect: {
        name: get(
          layoutConfig,
          'DEFAULT_LAYOUT.routes.rejectedApplication',
          ''
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "post-aip-reject" */ '@/views/PostAipReject.vue'
        ),
    },
    qualRoutes,
    gpayQualRoutes,
    magicLinkRoutes,
    loanRestructureRoutes,
    {
      path: '/error',
      name: 'genericError',
      props: true,
      component: () => import('@/views/CustomError.vue'),
    },
    {
      path: '*',
      name: '404 Not Found',
      component: () =>
        import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
    },
    {
      path: '/success-page',
      name: 'OperationSuccess',
      component: () =>
        import(
          /* webpackChunkName: "not-found" */ '@/views/SuccessOperationPage.vue'
        ),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let requiresAuth = true;
  requiresAuth = to.matched.some((record: any) => record.meta.requiresAuth);
  const queryObj: any = to.query || {};
  const isCtxAuthenticated = await isCustomerAuthenticated(authState());
  console.log(to.hash);
  if (to.hash.length > 0) {
    next(to.hash.split('#')[1]);
  }
  if (queryObj.ml) {
    next({
      name: 'home-ml',
      params: { linkId: queryObj.ml },
    });
  }
  if (queryObj.token) {
    storeAccessDetails({
      payload: { access_token: queryObj.token, authenticated: true },
    });
    try {
      await retrieveBigCookie(true, 'router:beforeEach');
      next();
    } catch (error: any) {
      console.log(error);
      if (!(error && error.status)) {
        next({
          name: get(currentLayoutConfig(), 'routes.login', 'customerLogin'),
          query: { redirect: to.name, ...to.query },
        });
      } else {
        const newQueryParams: any = { redirect: to.name };
        Object.keys(to.query).map((val) => {
          if (val === 'token') return;
          newQueryParams[val] = to.query[val];
        });
        next({
          name: get(currentLayoutConfig(), 'routes.login', 'customerLogin'),
          query: { ...newQueryParams },
        });
      }
    }
  } else if (
    requiresAuth &&
    !isCtxAuthenticated &&
    !['customerLogin', 'gpay-customerLogin'].includes(to.name as string)
  ) {
    next({
      name: get(currentLayoutConfig(), 'routes.login', 'customerLogin'),
      query: { redirect: to.name, ...to.query },
    });
  } else {
    next();
  }
});

export default router;
