import { Offers } from '@/interfaces/offers';
import { isEmpty } from '@/lib/helpers';
import { store } from '@/store/index';
import { SequentialFlowStore } from '@/views/seq_flow/seqFlowModel';

const appConfig = () => {
  const state: any = store.getState();
  return state.appConfig;
};

export const layoutConfig = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.layoutConfig;
  }
  return {};
};

export const currentLayout = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.currentLayout;
  }
  return 'DEFAULT_LAYOUT';
};

export const currentLanguage = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.language;
  }
  return 'en';
};

export const lastBigCookieHitAt = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.lastBigCookieHit;
  }
  return null;
};

export const getSelectedTenureData = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.selectedTenureData;
  }
  return null;
};

export const getTransactionData = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.txnResponse;
  }
  return null;
};

export const getGeolocation = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.geolocation;
  }
  return false;
};

export const qualFlowType = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.qualFlowType;
  }
  return null;
};

export const sequentialFlow = () => {
  const applicationConfig: any = appConfig();
  if (applicationConfig && !isEmpty(applicationConfig.sequential)) {
    return applicationConfig.sequential;
  }
  return null;
};

export const getKycOffers = (): Offers | null => {
  const applicationConfig: any = appConfig();
  if (applicationConfig) {
    return applicationConfig.kycOffers;
  }
  return null;
};
