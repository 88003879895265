export const SET_LAYOUT_CONFIG = 'APPCONFIG/SET_LAYOUT_CONFIG';
export const SET_LANGUAGE = 'APPCONFIG/SET_LANGUAGE';
export const SET_LAST_BIG_COOKIE_HIT = 'APPCONFIG/SET_LAST_BIG_COOKIE_HIT';
export const UPDATE_TENURE_DATA = 'APPCONFIG/UPDATE_TENURE_DATA';
export const UPDATE_TRX_DATA = 'APPCONFIG/UPDATE_TRX_DATA';
export const UPDATE_GEOLOCATION = 'APPCONFIG/UPDATE_GEOLOCATION';
export const SET_QUAL_FLOW_TYPE = 'APPCONFIG/SET_QUAL_FLOW_TYPE';
export const SET_SEQUENTIAL_DATA = 'APPCONFIG/SET_SEQUENTIAL_DATA';
export const SET_SHOW_OLD_FLOW = 'APPCONFIG/SET_SHOW_OLD_FLOW';
export const SET_KYC_OFFERS = 'APPCONFIG/SET_KYC_OFFERS';
