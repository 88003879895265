import { store } from '@/store';
import * as types from '../action_types/app_config';
import { getLocalKey, setLocalKey } from '../local_store';
import { QUAL_FLOW_TYPE } from '../store_keys';
import bus from '@/lib/event_bus';
import BigCookie from '@/business/models/big_cookie';
import { Offers } from '@/interfaces/offers';

export function setLayoutConfig(data: { payload: any }) {
  store.dispatch({
    type: types.SET_LAYOUT_CONFIG,
    payload: data.payload,
    path: ['layoutConfig'],
  });
}

export function setLanguage(data: { payload: any }) {
  store.dispatch({
    type: types.SET_LANGUAGE,
    payload: data.payload,
    path: ['language'],
  });
}

export function setLastBigCookieHitAt(data: { payload: any }) {
  store.dispatch({
    type: types.SET_LAST_BIG_COOKIE_HIT,
    payload: data.payload,
    path: ['lastBigCookieHit'],
  });
}

export function updateTenureData(data: { payload: any }) {
  store.dispatch({
    type: types.UPDATE_TENURE_DATA,
    payload: data.payload,
    path: ['selectedTenureData'],
  });
}

export function updateTransactionData(data: { payload: any }) {
  store.dispatch({
    type: types.UPDATE_TRX_DATA,
    payload: data.payload,
    path: ['txnResponse'],
  });
}

export function updateGeolocation(data: { payload: any }) {
  store.dispatch({
    type: types.UPDATE_GEOLOCATION,
    payload: data.payload,
    path: ['geolocation'],
  });
}

export function setQualFlowType(data: { payload: any }) {
  let qualFlowType = getLocalKey(QUAL_FLOW_TYPE);
  if (qualFlowType) {
    return;
  }
  if (data.payload) {
    qualFlowType = data.payload;
  } else {
    qualFlowType = 'NEW_CONTROL';
  }
  store.dispatch({
    type: types.SET_QUAL_FLOW_TYPE,
    payload: qualFlowType,
    path: ['qualFlowType'],
  });
  setLocalKey(QUAL_FLOW_TYPE, qualFlowType);
  bus.$emit('capture-qual-flow-type', qualFlowType);
}

export function updateSequentialFlow(actionables: any[]) {
  //call a get api and update the state
  const payload = {
    currStep: -1,
    maxSteps: actionables.length,
    currStepName: '',
    started: true,
  };
  for (const [index, actionable] of actionables.entries()) {
    if (actionable.status === 'PENDING') {
      payload.currStep = index + 1;
      payload.currStepName = actionable.actionType;

      break;
    }
  }
  if (payload.currStep === -1) {
    payload.currStep = payload.maxSteps + 1;
  }

  store.dispatch({
    type: types.SET_SEQUENTIAL_DATA,
    payload: payload,
    path: ['sequential'],
  });
}

export function updateShowOldFlow(show: boolean) {
  store.dispatch({
    type: types.SET_SHOW_OLD_FLOW,
    payload: show,
    path: ['showOldFlow'],
  });
}

export function updateKycOffers(offerData: Offers | null) {
  if (
    offerData &&
    offerData.stepWiseDetailList &&
    offerData.stepWiseDetailList.length > 0
  ) {
    store.dispatch({
      type: types.SET_KYC_OFFERS,
      payload: offerData,
      path: ['kycOffers'],
    });
  }
}
